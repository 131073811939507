/* ProductCartDetail.css */
.product-cart-detail {
    display: flex;
    padding-top: 20px;
    width: 70%;
    margin: 0 auto; /* Center the component horizontally */
}
  
  .product-image {
    width: 30%;
  }
  
  .product-image img {
    width: 100%;
  }
  
  .product-details {
    width: 70%;
    padding-left: 20px;
  }
  
  .source-logo {
    width: 40px;
    height: 40px;
  }
  
  .price-button {
    background-color: blue;
    color: white;
    margin-bottom: 10px;
    border: none;
    padding: 10px;
    font-size: 1em;
    cursor: pointer;
  }
/* ProductCartDetail.css */
.buy-now-text {
    text-align: center; /* Center the text */
    margin-top: 5px;
    text-justify:auto;
    padding-top: 1px;
    padding-bottom: 0px;
    margin: 0px; /* Add top margin */
    color: rgb(28, 0, 99);
    font-weight: bold;
    
  }

  .product-price-detail.with-shadow {
    box-shadow: 0px 8px 10px -9px rgba(0.5, 0.5, 0.5, 0.5);
    /* Adjust the values as needed for the desired shadow effect */
}

/* Define styles for the product price detail */
.product-price-detail {
    display: inline-block; /* Ensure it wraps around the content */
    border: 2px solid black;
    background-color: rgb(216, 238, 254);
    border-radius: 10px;
    margin: 4px;
    transition: transform 0.2s ease;
    cursor: pointer;
    box-shadow: #00a40e; /* Add cursor pointer to indicate clickable */
  }
  
  /* Zoom effect on hover */
  .product-price-detail:hover {
    transform: scale(1.05); /* Increase scale on hover */
  }
  
  
  .product-price-detail-content {
    padding: 10px; /* Add padding to the content wrapper */
  }
  
  
  
  .star-rating {
    font-size: 1.3em;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 15px;  }
  
  .star-filled {
    color: gold; /* Change color of filled star */
  }
  
  .star-empty {
    color: lightgrey; /* Change color of empty star */
  }

  .product-card__logo {
    display: flex;
    align-items: center; /* Center the logo and the span vertically */
    width: 10%;
    height: 20px;
    margin-right: 10px;
  }
  .product-card__logo-image {
    width: 100%;
    height: auto;
  }
  .product-card__logo span:hover,
.product-card__span:hover {
  text-decoration: underline; /* Add underline effect on hover */
}  
.product-card__discount {
    font-size: 12px;
    font-weight: bold;
    color: #00a40e;
    margin-left: 5px;
  }
  /* ProductCartDetail.css */

  /* Apply mobile-specific layout using media query */
@media only screen and (max-width: 600px) {
    /* Image should appear above details on mobile */
    .product-cart-detail {
      flex-direction: column;
      width: 100%;
    }
  
    /* Image should take full width on mobile */
    .product-image {
        width: 80%;
        margin: 20px auto; /* Center the container horizontally and add top margin */
        display: flex;
        justify-content: center; /* Center the child elements horizontally */
        align-items: center; /* Center the child elements vertically */
    }
    
    .product-image img {
        max-width: 100%; /* Ensure images don't exceed the width of their container */
        max-height: 100%; /* Ensure images don't exceed the height of their container */
        display: block; /* Remove default inline display */
        margin: auto; /* Center the images within their container */
    }
    .product-image img {
        width: 100%;
      }
  
    /* Reset margin and padding for product price detail on mobile */
    .product-price-detail {
      margin-top: 0;
      padding: 0;
    }
  }

  