.footer {
    background-color: #6eabf5;
    color: #000000;
    padding: 40px 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    margin-right: 20px;
  }
  
  .footer-section h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 8px;
  }
  
  .footer-section ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-bottom {
    margin-top: 20px;
    text-align: center;
    font-size: 0.9rem;
  }
  