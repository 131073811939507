.app-container {
  width: 100%;
  overflow-y: auto;
  background: linear-gradient(
    102deg,
    #b8d6ee 2.92%,
    #fff 27.27%,
    #c7edfd 54.06%,
    #fff 74.96%,
    #fcddc6 95.45%
  );
  height: calc(100vh - 156px);
}

/* @media (max-width: 768px) {
  height: calc(100vh - 156px); */
