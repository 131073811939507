/* Services.css */
.services-header {
  text-align: center;
  margin: 20px 0;
  color: blue;
}

.services-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh; /* Adjust height as needed */
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  max-width: 800px; /* Center the content within a maximum width */
}
