.about-container {
  text-align: center;
  margin: 50px auto; /* Center the container */
}

.about-header {
  color: blue;
  font-weight: bold;
}

.about-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 20px auto;
}

.about-text {
  text-align: center; /* Center align text */
  flex: 1; /* Take up remaining space */
  text-align: justify;
}

.about-image {
  margin-left: 20px; /* Space between text and image */
  text-align: center; /* Center align the content */
  width: 300px; /* Set a fixed width for the image container */
  height: 300px; /* Set a fixed height for the image container */
  display: flex; /* Ensure flex layout for centering */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
}

.about-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-robot-description {
  font-size: 0.9rem;
  color: #666;
}

/* Responsive styling for smaller screens */
@media (max-width: 767px) {
  .about-content {
    flex-direction: column; /* Stack content vertically on smaller screens */
    align-items: center; /* Center align items */
  }

  .about-image {
    width: 80%; /* Take 80% of the screen width */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space between text and image */
    padding-top: 30px;
  }

  .about-text {
    text-align: justify; /* Align text to the left */
    padding: 30px;

  }
}

/* Styles for the "Know More" header */
.know-more {
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
  transition: all 0.3s ease;
  position: relative;
}

.know-more:hover {
  text-decoration: underline;
  color: #714FFF; /* Optional: Change color on hover */
}

/* Display additional message on hover */
.know-more::after {
  content: "Click here";
  position: absolute;
  top: 100%; /* Position below the main text */
  left: 50%;
  transform: translateX(-50%);
  font-style: normal;
  font-size: 0.9rem;
  color: #666;
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-top: 5px; /* Spacing between main text and tooltip */
}

.know-more:hover::after {
  opacity: 1; /* Show the hover text */
}
