/* Container for the card */
.card-container {
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    background-color: #fff;
    margin: 15px;
    transition: all 0.3s ease-in-out;
}


/* Image container */
.image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    /* background-color: #e1f4ff; */
    border-radius: 3%;
}

/* Image styling */
.card-image {
    width: 270px;
    height: 350px;
    object-fit: cover;
    border-radius: 50%;
}

/* Text container */
.text-container {
    margin-top: 5px;
}

/* Name styling */
.card-name {
    margin: 10px 0 5px;
    font-size: 18px;
    color: #333;
}

/* Position styling */
.card-position {
    margin: 0;
    font-size: 14px;
    color: #555;
}

/* Location styling */
.card-location {
    margin: 5px 0 0;
    font-weight: bold;
    font-size: 20px;
    color: #0044ff;
}

/* Responsive Design for Tablets */
@media (max-width: 768px) {
    .card-container {
        width: 90%;
        padding: 10px;
    }

    .card-image {
        width: 200px;
        height: 200px;
    }

    .card-name {
        font-size: 16px;
    }

    .card-position,
    .card-location {
        font-size: 12px;
    }
}

/* Responsive Design for Phones */
@media (max-width: 480px) {
    .card-container {
        width: 95%;
        margin: 10px 0;
    }

    .card-image {
        width: 200px;
        height: 200px;
    }

    .card-name {
        font-size: 14px;
    }

    .card-position,
    .card-location {
        font-size: 10px;
    }
}