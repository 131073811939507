/* Contact.css */
.contact-form {
  max-width: 600px;
  margin: 50px auto; /* Auto margin for centering horizontally */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  display: inline-block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.home-header {
  font-size: 50px;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
  color: rgb(8, 102, 243); 
  background-color: rgb(255, 255, 255);
  text-shadow: 5ch;
  font-weight: bold;
  border-radius: 5px;
  font-family: Cursive, sans-serif; 
  padding: 10px;
}

/* ContactInfo.css */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow boxes to wrap to the next line */
  margin: 20px 0;
  height: 20vh; /* Make sure the container takes up the full viewport height */
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 10px;
  margin: 10px; /* Add some margin between boxes */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth hover effect */
  cursor: pointer;
}

.box:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add a larger shadow on hover */
}

.icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .backgroundDiv {
    height: 50vh; /* Adjust height for smaller screens */
  }

  .contact-form {
    max-width: 600px;
    margin: 15px; 
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .container {
    flex-direction: column; /* Stack the boxes vertically */
    height: auto; /* Adjust the height for smaller screens */
    margin: 0 10px;
  }

  .home-header {
    font-size: 20px;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: rgb(8, 102, 243); 
    background-color: rgb(255, 255, 255);
    text-shadow: 5ch;
    font-weight: bold;
    border-radius: 5px;
    font-family: Cursive, sans-serif; 
    padding: 10px;
  }

  .box {
    width: 100%; /* Make each box take the full width */
    max-width: 300px; /* Optional: Limit the maximum width of each box */
    margin: 10px auto; /* Center the boxes horizontally */
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow boxes to wrap to the next line */
  margin: 20px 0;
  height: 20vh; /* Make sure the container takes up the full viewport height */
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 10px;
  margin: 10px; /* Add some margin between boxes */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth hover effect */
  cursor: pointer;
}

.box:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add a larger shadow on hover */
}

.icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

/* Ensure text wraps and is centered */
.box p {
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  margin: 0;
}

@media (max-width: 768px) {
  .backgroundDiv {
    height: 50vh; /* Adjust height for smaller screens */
  }

  .contact-form {
    max-width: 600px;
    margin: 15px; 
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .container {
    flex-direction: column; /* Stack the boxes vertically */
    height: auto; /* Adjust the height for smaller screens */
    margin: 0 10px;
  }

  .home-header {
    font-size: 20px;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: rgb(8, 102, 243); 
    background-color: rgb(255, 255, 255);
    text-shadow: 5ch;
    font-weight: bold;
    border-radius: 5px;
    font-family: Cursive, sans-serif; 
    padding: 10px;
  }

  .box {
    width: 100%; /* Make each box take the full width */
    max-width: 300px; /* Optional: Limit the maximum width of each box */
    margin: 10px auto; /* Center the boxes horizontally */
  }
}

