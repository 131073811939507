/* src/components/PhoneToggle/PhoneToggle.css */
.phone-toggle {
    position: fixed;
    top: 70px; /* Adjust based on the height of your NavigationBar */
    width: 100%;
    height: 26%;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow effect */
    z-index: 1000;
    overflow-y: auto; /* Enable scrolling if content overflows */
  }
  
  .phone-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;  /* Ensure nav takes full width */
  }
  
  .phone-nav-item {
    margin: 0;
    padding: 10px 20px;  /* Padding for spacing */
    text-align: left;  /* Align text to the left */
    border-bottom: 1px solid #000; /* Black separator line */
  }
  
  .phone-nav-link {
    text-decoration: none;
    color: #000000;
    font-size: 18px;  /* Font size adjustment */
    display: block;  /* Ensure the link takes up the full item */
  }
  
  .phone-nav-link:hover {
    text-decoration: underline;  /* Hover effect */
  }
  