/* Reset margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container for the logo and header */
.nav-container {
  background-color: rgb(8, 102, 243); /* Blue background for the header */
  width: 100%;
}

.header_image {
  display: flex;
  justify-content: space-between; /* Space between logo and toggle */
  align-items: center;
  padding: 10px 20px;
  background-color: rgb(8, 102, 243); /* Blue background for the logo container */
}

.nev-logo {
  display: flex;
  align-items: center;
  max-width: 100px;
  max-height: 100px;
  padding: 5px;
  margin: 10px;
}

.nev-logo img {
  max-width: 100%;
  height: auto;
}

/* Styles for the toggle button */
.navbar-toggle {
  font-size: 24px;
  cursor: pointer;
  display: none; /* Hide toggle button by default */
}

/* Styles for the navbar */
.navbar {
  background-color: #ffffff;
  border-bottom: none; /* Remove the bottom border */
  border: 2px black;
  border-radius: 5px;
}

.navbar-nav {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1; /* Allow the nav items to grow and take up space */
}

.nav-item {
  position: relative; /* Needed for dropdown positioning */
  margin: 10px 0;
  transition: transform 0.2s;
}

.nav-link {
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding: 10px; /* Add padding inside the box */
  display: block; /* Ensure links are displayed as blocks */
}

.active-link {
  font-weight: bold;
}

/* Dropdown menu styles */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none; /* Hide by default */
}

.dropdown-menu.open {
  display: block; /* Show dropdown when open class is present */
}

.dropdown-item {
  padding: 10px;
  list-style: none; /* Remove bullet points */
  border-bottom: 1px solid #ccc; /* Add border bottom */
}

.dropdown-item .service-link {
  color: black;
  text-decoration: none;
  display: block;
}

.dropdown-item:last-child {
  border-bottom: none; /* Remove bottom border for last item */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .header_image {
    justify-content: space-between; /* Space between logo and toggle */
  }

  .nev-logo {
    justify-content: flex-start;
    max-width: 50px;
    max-height: 50px;
    padding: 3px;
    margin: 5px;
  }

  .navbar-toggle {
    display: block; /* Show toggle button on smaller screens */
    align-self: flex-end; /* Align toggle button to the right */
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start; /* Align nav items to the left */
    padding-left: 20px; /* Add some padding for better alignment */
  }

  .navbar-nav {
    display: none;
    flex-direction: column;
    width: 100%;
    align-items: flex-start; /* Align nav items to the left */
    margin-top: 10px;
  }

  .navbar-nav.open {
    display: flex; /* Show navbar when menu is open */
  }

  .nav-item {
    width: 100%; /* Take full width */
    border: 1px solid black; /* Add border to create a box-like appearance */
    padding: 10px; /* Add padding inside the box */
    margin: 10px 0; /* Adjust margin for spacing */
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    border: none;
    display: none; /* Hide dropdown by default */
  }

  .nav-item:hover .dropdown-menu {
    display: block; /* Show dropdown on hover */
  }

  .nav-item.dropdown-open .dropdown-menu {
    display: block; /* Show dropdown when parent has class 'dropdown-open' */
  }

  /* Resize logo on smaller screens */
  .nev-logo img {
    width: 150px; /* Adjust width as needed */
  }
}

@media (max-width: 480px) {
  /* Further resize logo on very small screens */
  .nev-logo img {
    width: 100px; /* Adjust width as needed */
  }
}

@media (min-width: 769px) {
  .header_image {
    justify-content: space-between; /* Space between logo and toggle */
  }

  .navbar-toggle {
    display: none; /* Hide toggle button on larger screens */
  }

  .nav-item:hover {
    transform: scale(1.1); /* Apply hover effect only on laptops */
  }

  /* Default logo size for larger screens */
  .nev-logo img {
    width: 200px; /* Adjust width as needed */
  }
}
