/* Container for the entire advisers section */
.advisers-container {
    padding: 30px 20px;
}

/* Title of the section */
.advisers-title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
}

/* Grid container for adviser cards */
.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

/* Card container with shadow effect */
.card-container {
    width: 400px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow by default */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    padding: 15px;
    text-align: center;
    margin: 10px;
}

/* Hover effect for the card */
.card-container:hover {
    transform: scale(1.05); /* Slight zoom */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}
