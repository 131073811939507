.it-services-container {
  text-align: center;
  padding: 20px;
}


.it-services-container h2 {
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: rgb(107, 0, 47); /* Adjust color as needed */
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 30px;
  margin: 15px;
  width: 300px; /* Adjust width as needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .service-card {
    width: calc(100% - 30px); /* Adjust width for smaller screens */
  }
}

.service-card:hover {
  transform: scale(1.05);
  border: 1px solid black;
}

.service-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: center;
  background: linear-gradient(to right, #00ff00, #0000ff); /* Adjust gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.it-services-container h1 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  text-align: center;
  background: linear-gradient(to right, #00ff00, #0000ff); /* Adjust gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-card p {
  text-align: justify;
  margin: 10px 0;
  line-height: 1.2;
  text-indent: 2em;
}

.service-card-image {
  width: 100%; /* Ensure image fills the card width */
  height: auto; /* Allow image height to adjust proportionally */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  /* Optional: Add a slight border or margin for separation */
}