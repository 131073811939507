/* Card.css */
.product-card-container {
  flex: 1 1 30%; /* Flex properties to allow responsiveness */
  display: flex;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.product-card {
  width: 100%;
  max-width: 500px; /* Consistent card size */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.product-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

.product-card__header {
  color: blue;
  font-size: 24px;
  margin: 10px 0 20px 0; /* 10px margin at the top */
}

.product-card__text {
  color: black;
  font-size: 16px;
  text-align: left; /* Add this line to align text to the left */
}


/* Responsive Styles */
@media (max-width: 768px) {
  .product-card-container {
    flex: 1 1 100%; /* Full width on mobile */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .product-card-container {
    flex: 1 1 45%; /* Two cards per row on tablet */
  }
}

@media (min-width: 1025px) {
  .product-card-container {
    flex: 1 1 30%; /* Three cards per row on laptop/desktop */
  }
}
