/* Gallery.css */

.gallery-container {
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.gallery-header {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 images per row */
  gap: 10px; /* Space between the images */
}

.image-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px; /* Optional: make corners rounded */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .image-grid {
    grid-template-columns: 1fr; /* 1 image per row on very small screens */
  }
}
