/* General container styling for the form */
.form-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  /* Header styling */
  .form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
  
  /* Styling for form items (label and input alignment) */
  .ant-form-item {
    margin-bottom: 16px;
  }
  
  .ant-form-item-label > label {
    font-weight: 600;
    color: #555;
  }
  
  /* Input fields styling */
  .ant-input,
  .ant-select-selector,
  .ant-input-number,
  .ant-upload-drag {
    border-radius: 6px;
  }
  
  /* Placeholder text color for better aesthetics */
  .ant-input::placeholder,
  .ant-select-selection-placeholder,
  .ant-input-number::placeholder {
    color: #aaa;
  }
  
  /* Button styling */
  .ant-btn-primary {
    background-color: #4CAF50;
    border-color: #4CAF50;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    border-radius: 6px;
  }
  
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: #45a049;
    border-color: #45a049;
  }
  
  /* Resume upload box */
  .ant-upload-drag {
    background-color: #f9f9f9;
    border: 2px dashed #d9d9d9;
    padding: 24px;
    text-align: center;
    transition: all 0.3s ease;
    border-radius: 8px;
  }
  
  .ant-upload-drag:hover {
    border-color: #4CAF50;
  }
  
  /* Cover letter textarea */
  .ant-input-textarea {
    resize: none;
    border-radius: 6px;
  }
  
  /* Responsive design for mobile devices */
  @media (max-width: 768px) {
    .form-container {
      margin: 10px;
      padding: 15px;
    }
  
    .form-container h2 {
      font-size: 20px;
    }
  
    .ant-btn-primary {
      font-size: 14px;
      height: 44px;
    }
  }
  
  /* Responsive design for very small devices */
  @media (max-width: 480px) {
    .form-container {
      padding: 10px;
    }
  
    .form-container h2 {
      font-size: 18px;
    }
  
    .ant-btn-primary {
      font-size: 14px;
      height: 40px;
    }
  
    .ant-upload-drag {
      padding: 20px;
    }
  }
  