/* Default styles */
.home-header {
  font-size: 50px;
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
  color: rgb(8, 102, 243); 
  background-color: rgb(255, 255, 255);
  text-shadow: 5ch;
  font-weight: bold;
  border-radius: 5px;
  font-family: Cursive, sans-serif; 
  padding: 10px;
}

/* ContactInfo.css */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow boxes to wrap to the next line */
  margin: 20px 0;
  height: 20vh; /* Make sure the container takes up the full viewport height */
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  padding: 10px;
  margin: 10px; /* Add some margin between boxes */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.backgroundDiv {
  background-image: url('../../images/CompanyImage1.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white; /* Example for text color */
}

.navigate-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: rgb(0, 0, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navigate-button:hover {
  background-color: darkblue;
}

@media (max-width: 768px) {
  .backgroundDiv {
    height: 50vh; /* Adjust height for smaller screens */
  }
  
  .container {
    flex-direction: column; /* Stack the boxes vertically */
    height: auto; /* Adjust the height for smaller screens */
    margin: 0 10px;
  }
  .home-header {
    font-size: 20px;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: rgb(8, 102, 243); 
    background-color: rgb(255, 255, 255);
    text-shadow: 5ch;
    font-weight: bold;
    border-radius: 5px;
    font-family: Cursive, sans-serif; 
    padding: 10px;
  }

  .box {
    width: 100%; /* Make each box take the full width */
    max-width: 300px; /* Optional: Limit the maximum width of each box */
    margin: 10px auto; /* Center the boxes horizontally */
  }
}
